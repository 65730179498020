import React from 'react'
import { FaFacebookF } from 'react-icons/fa';
import { BsInstagram } from 'react-icons/bs';
import { FaYoutube } from 'react-icons/fa';
import {BsTwitter } from 'react-icons/bs';
import {SiGooglemaps } from 'react-icons/si';

const Sociallink = () => {
  return (
    <div>
      <div className='sociallinkfooter'>
        <div className="row">
            <div className="col">
              <div className='objectcenter'>
           <a href="https://www.facebook.com/group.skworld"> <FaFacebookF/></a>
            </div>
            </div>
            <div className="col">
              <div  className='objectcenter'>
                <a href="https://www.facebook.com/group.skworld">
              <BsInstagram/></a>
              </div>
            </div >
            <div className="col">
              <div  className='objectcenter'>
                <a href='https://www.youtube.com/@skworldevents5572'>
              <FaYoutube/>
              </a>
              </div>
              
            </div>
            <div className="col">
              <div  className='objectcenter'>
             <a href='https://maps.app.goo.gl/9c9aVUEQyqxUQFUv7'><SiGooglemaps/></a> 
              </div>
           
            </div>
        </div>
      </div>
    </div>
  )
}

export default Sociallink
