

import React, { useState } from 'react';
import Home from './pages/Home';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Aboutus from './pages/Aboutus';
import Contectus from './pages/Contectus';
import Services from './pages/services';
import ScrollToTop from './components/Scrolltop';
import Gallery1 from './pages/Gallery';
import Error from "./components/error"
function App() {
  return (
    <div className="App" style={{overflow:"hidden", }}>
    <BrowserRouter> 
    <ScrollToTop />
       <Routes>

      <Route path='/' element={<Home/>}></Route>
      <Route path='/event-management-company-andheri' element={<Aboutus/>}></Route>
      <Route path='/celebrity-management-company-andheri' element={<Services/>}></Route>
      <Route path='/top-event-management-company-andheri' element={<Gallery1/>}></Route>
      <Route path='/best-celebrity-management-company-in-andheri' element={<Contectus/>}></Route>
      <Route path='/*' element={<Error/>}></Route>
       </Routes>
     </BrowserRouter>
   </div>
  );
}

export default App;
