
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/free-mode';
// Import Swiper styles
import 'swiper/css';
import move from "../assets/product/movie.jpeg"
import mobie2 from "../assets/product/movie2.jpg"
import MailPop from "../components/Mailpopup"
import React, { useRef, useLayoutEffect, useState } from "react";
import '../css/style.css'
// import required modules
import { Autoplay , FreeMode} from 'swiper/modules';

const Admaking = () => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <div>
    <div className="row">

     <Swiper
     style={{
       height:"570px"
     }}
           slidesPerView={3}
           spaceBetween={30}
           autoplay={{
             delay: 2500,
             disableOnInteraction: false,
           }}
           breakpoints={{
             0: {
               slidesPerView: 1,
               spaceBetween: 30,
             },
             768: {
               slidesPerView: 2,
               spaceBetween: 30,
             },
             1000: {
               slidesPerView: 3,
               spaceBetween: 30,
             },
           }}
           freeMode={true}
           pagination={{
             clickable: true,
           }}
           modules={[Autoplay,FreeMode]}
            
           className="mySwiper"
         >
   <SwiperSlide>
   <div className="">
 <div className="owl-item" style={{ width: 400, padding:"10px" }}>
<div className="item">
<div className="event-item">
 
 <div className="event-image1">
   <img
     src={move}
     alt="song maker in andheri"
   />
  
 </div>
 <div className="event-content">
   <div className="event-title mb-30">
     <h3 className="title">Tadap</h3>
     <p style={{textAlign:"center"}}>(song)</p>
     
   </div>
   
   <div style={{display:"flex",justifyContent:"center"}}>
<button href="#!" className="custom-btn" onClick={() => setModalShow(true)}>
Enquiry Now
</button>
<MailPop
        show={modalShow}
        onHide={() => setModalShow(false)}
      /> 
</div>
 </div>
</div>
</div>
</div>
 </div>
 </SwiperSlide>

  <SwiperSlide>
 <div className="">
 <div className="owl-item widpad" >
<div className="item">
<div className="event-item">
 
 <div className="event-image1">
   <img
     src={mobie2}
     alt="best movie maker in andheri"
   />
  
 </div>
 <div className="event-content">
   <div className="event-title mb-30">
     <h3 className="title">Masterchef</h3>
     <p style={{textAlign:"center"}}>(Movie)</p>
   </div>
   
   <div style={{display:"flex",justifyContent:"center"}}>
<button href="#" className="custom-btn" onClick={() => setModalShow(true)}>
Enquiry Now
</button>
<MailPop
        show={modalShow}
        onHide={() => setModalShow(false)}
      /> 
</div>
 </div>
</div>
</div>
</div>
 </div>
 </SwiperSlide>
 <SwiperSlide>
   <div className="">
 <div className="owl-item" style={{ width: 400, padding:"10px" }}>
<div className="item">
<div className="event-item">
 
 <div className="event-image1">
   <img
     src={move}
     alt="song maker in andheri"
   />
  
 </div>
 <div className="event-content">
   <div className="event-title mb-30">
     <h3 className="title">Tadap</h3>
     <p style={{textAlign:"center"}}>(song)</p>
     
   </div>
   
   <div style={{display:"flex",justifyContent:"center"}}>
<button href="#!" className="custom-btn" onClick={() => setModalShow(true)}>
Enquiry Now
</button>
<MailPop
        show={modalShow}
        onHide={() => setModalShow(false)}
      /> 
</div>
 </div>
</div>
</div>
</div>
 </div>
 </SwiperSlide>

  <SwiperSlide>
 <div className="">
 <div className="owl-item widpad" >
<div className="item">
<div className="event-item">
 
 <div className="event-image1">
   <img
     src={mobie2}
     alt="best movie maker in andheri"
   />
  
 </div>
 <div className="event-content">
   <div className="event-title mb-30">
     <h3 className="title">Masterchef</h3>
     <p style={{textAlign:"center"}}>(Movie)</p>
   </div>
   
   <div style={{display:"flex",justifyContent:"center"}}>
<button href="#" className="custom-btn" onClick={() => setModalShow(true)}>
Enquiry Now
</button>
<MailPop
        show={modalShow}
        onHide={() => setModalShow(false)}
      /> 
</div>
 </div>
</div>
</div>
</div>
 </div>
 </SwiperSlide>
 <SwiperSlide>
   <div className="">
 <div className="owl-item" style={{ width: 400, padding:"10px" }}>
<div className="item">
<div className="event-item">
 
 <div className="event-image1">
   <img
     src={move}
     alt="song maker in andheri"
   />
  
 </div>
 <div className="event-content">
   <div className="event-title mb-30">
     <h3 className="title">Tadap</h3>
     <p style={{textAlign:"center"}}>(song)</p>
     
   </div>
   
   <div style={{display:"flex",justifyContent:"center"}}>
<button href="#!" className="custom-btn" onClick={() => setModalShow(true)}>
Enquiry Now
</button>
<MailPop
        show={modalShow}
        onHide={() => setModalShow(false)}
      /> 
</div>
 </div>
</div>
</div>
</div>
 </div>
 </SwiperSlide>

  <SwiperSlide>
 <div className="">
 <div className="owl-item widpad" >
<div className="item">
<div className="event-item">
 
 <div className="event-image1">
   <img
     src={mobie2}
     alt="best movie maker in andheri"
   />
  
 </div>
 <div className="event-content">
   <div className="event-title mb-30">
     <h3 className="title">Masterchef</h3>
     <p style={{textAlign:"center"}}>(Movie)</p>
   </div>
   
   <div style={{display:"flex",justifyContent:"center"}}>
<button href="#" className="custom-btn" onClick={() => setModalShow(true)}>
Enquiry Now
</button>
<MailPop
        show={modalShow}
        onHide={() => setModalShow(false)}
      /> 
</div>
 </div>
</div>
</div>
</div>
 </div>
 </SwiperSlide>

  


 </Swiper>
 </div> 
</div>
  )
}

export default Admaking
