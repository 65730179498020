import React from 'react'
import logo from "../assets/logo.png"
import "../css/footer.css"
import { FaLocationDot,FaFacebookF } from 'react-icons/fa6';
import { AiFillMail,AiOutlineInstagram } from 'react-icons/ai';
import { BiSolidPhoneCall } from 'react-icons/bi';
import { BsTwitter } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const Footer = () => {
  return (
    <div>
      <footer
  id="footer-section"
  className="footer-section footer-section2 clearfix"
  style={{backgroundColor:"black"}}
>
<div style={{borderTop:"1px solid white"}}>

</div>
  {/* footer-top - start */}
  <div className="footer-top sec-ptb-100 clearfix">
    <div className="container">
      <div className="row">
        {/* about-wrapper - start */}
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="about-wrapper">
            {/* site-logo-wrapper - start */}
            <div className="site-logo-wrapper mb-30">
              <a href="#" className="logo">
                <Link to="/">
                <img src={logo} className='logowidth' alt="logo_not_found" />
                </Link>
              </a>
            </div>
            {/* site-logo-wrapper - end */}
            {/* <p className="mb-30">
              Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam
              nonummy nibh euismod tincidunt ut laoreet dolore magna.
            </p> */}
            {/* basic-info - start */}
            <div className="basic-info ul-li-block mb-50">
          
              <ul>
                <li style={{display:"flex"}}>
                  <div className="fas fa-map-marker-alt" style={{marginRight:"10px", fontSize:"20px"}} >
                  <FaLocationDot/>
                    </div>
                    Kuber Complex, 115,1st, New Link Rd, Andheri West, Mumbai, Maharashtra 400053
                </li>
                <li style={{display:"flex"}}>
                  <div className="fas fa-envelope" style={{marginRight:"10px", fontSize:"20px"}} >
                  <AiFillMail/>
                    </div>
                  <a href="#">skworldgroup@gmail.com</a>
                </li>
                <li style={{display:"flex" }}>
                  <div className="fas fa-phone" style={{marginRight:"10px", fontSize:"20px"}} >
                  <BiSolidPhoneCall/>
                    </div>
                  <a href="#">9892331133</a>
                </li>
              </ul>
            </div>
            {/* basic-info - end */}
            {/* social-links - start */}
            <div className="social-links ul-li">
              <h3 className="social-title">network</h3>
              <ul>
                <li>
                  <a href="https://www.facebook.com/group.skworld">
                    <div className="fab fa-facebook-f">
                    <FaFacebookF/>
                      </div>
                  </a>
                </li>
                <li>
                  <a href="https://www.instagram.com/sk_world_pvt_ltd/">
                    <i className="fab fa-twitter" />
                    <AiOutlineInstagram/>
                  </a>
                </li>
               
                <li>
                  <a href="#">
                    <i className="fab fa-google-plus-g" />
                  </a>
                </li>
                <li>
                  <a href="#">
                    <i className="fab fa-instagram" />
                  </a>
                </li>
              </ul>
            </div>
            {/* social-links - end */}
          </div>
        </div>
        {/* about-wrapper - end */}
        {/* usefullinks-wrapper - start */}
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="usefullinks-wrapper ul-li-block">
            <h3 className="footer-item-title">
              useful <strong>links</strong>
            </h3>
            <ul>
              <li>
                <Link to="/"> Home</Link>
               
              </li>
              <li>
              <Link to="/event-management-company-andheri">About Us</Link>
               
              </li>
              <li>
              <Link to="/celebrity-management-company-andheri">Services</Link>
                
              </li>
              <li>
              <Link to="/top-event-management-company-andheri">Gallery</Link>
               
              </li>
              <li>
              <Link to="/best-celebrity-management-company-in-andheri">Contact Us</Link>
               
              </li>
              
            </ul>
          </div>
        </div>
        {/* usefullinks-wrapper - end */}
        {/* instagram-wrapper - start */}
        <div className="col-lg-4 col-md-6 col-sm-12">
          <div className="instagram-wrapper ul-li">
            <h3 className="footer-item-title">
              SK world <strong>Location</strong>
            </h3>
            <iframe
 src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d482475.1397475651!2d72.832383!3d19.136719!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b6236ab7a919%3A0xd09e3f0db62b7cf8!2sSK%20WORLD%20PRIVATE%20LIMITED!5e0!3m2!1sen!2sin!4v1694780960285!5m2!1sen!2sin"

  className='mapimg1'
  style={{ border: 0 ,borderRadius:"25px" }}
  allowFullScreen=""
  loading="lazy"
  referrerPolicy="no-referrer-when-downgrade"
/>
          </div>
        </div>
        {/* instagram-wrapper - end */}
      </div>
    </div>
  </div>
  {/* footer-top - end */}
  <div className="footer-bottom">
    <div className="container">
      <div className="row">
        {/* copyright-text - start */}
        <div className="col-lg-12 col-md-12 col-sm-12">
        <div className='Copy-Right'>
          
                    <div className='Contact-Us-Copy textpaddingbottem'>
                     All Rights Reserved by: 
                        <strong>
                            <span style={{ color: "white" }}> Sk World.in</span>
                        </strong>
                        
                    </div>
                   
                   
                </div>
        </div>
      
      </div>
    </div>
  </div>
</footer>

    </div>
  )
}

export default Footer
