
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/free-mode';
// Import Swiper styles
import 'swiper/css';
import MailPop from "../components/Mailpopup"
import '../css/style.css'
import React, { useRef, useLayoutEffect, useState } from "react";
import p1 from "../assets/product/p1.jpg"
import p2 from "../assets/product/p2.jpg"
import p3 from "../assets/product/p3.jpg"
// import required modules
import { Autoplay , FreeMode} from 'swiper/modules';
const ADDmaking = () => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <div>
         <div className="row">
     
          <Swiper
          style={{
            height:"360px"
          }}
                slidesPerView={3}
                spaceBetween={30}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 30,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                  1000: {
                    slidesPerView: 3,
                    spaceBetween: 30,
                  },
                }}
                freeMode={true}
                pagination={{
                  clickable: true,
                }}
                modules={[Autoplay,FreeMode]}
                 
                className="mySwiper"
              >
        <SwiperSlide>
        <div className="col-md-4">
      <div className="owl-item widpad" >
  <div className="item">
    <div className="event-item">
      
      <div className="event-image">
        <img
          src="https://i.imgur.com/MxP3fJv.png"
          alt="best Product Shooting Andheri "
        />
       
      </div>
      <div className="event-content">
        <div className="event-title mb-30">
          <h3 className="title">NEWTOWN GRAND</h3>
          
        </div>
        
        <div style={{display:"flex",justifyContent:"center"}}>
<button href="#" className="custom-btn" onClick={() => setModalShow(true)}>
Enquiry Now
</button>
<MailPop
        show={modalShow}
        onHide={() => setModalShow(false)}
      /> 
</div>
      </div>
    </div>
  </div>
</div>
      </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="col-md-4">
      <div className="owl-item widpad" >
  <div className="item">
    <div className="event-item">
      
      <div className="event-image">
        <img
          src="https://i.imgur.com/hfz2cCt.png"
          alt="best Ad Shooting Andheri"
        />
       
      </div>
      <div className="event-content">
        <div className="event-title mb-30">
          <h3 className="title">MADHUKUNJ GOLD AGARBATTI</h3>
          
        </div>
        
        <div style={{display:"flex",justifyContent:"center"}}>
<button href="#" className="custom-btn" onClick={() => setModalShow(true)}>
Enquiry Now
</button>
<MailPop
        show={modalShow}
        onHide={() => setModalShow(false)}
      /> 
</div>
      </div>
    </div>
  </div>
</div>
      </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="col-md-4">
      <div className="owl-item widpad" >
  <div className="item">
    <div className="event-item">
      
      <div className="event-image">
        <img
          src="https://i.imgur.com/uoNOmgZ.png"
          alt="best Product Shooting in andheri "
        />
       
      </div>
      <div className="event-content">
        <div className="event-title mb-30">
          <h3 className="title">EPARI SADASHIV JEWELLERS</h3>
          
        </div>
        
        <div style={{display:"flex",justifyContent:"center"}}>
<button href="#" className="custom-btn" onClick={() => setModalShow(true)}>
Enquiry Now
</button>
<MailPop
        show={modalShow}
        onHide={() => setModalShow(false)}
      /> 
</div>
      </div>
    </div>
  </div>
</div>
      </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="col-md-4">
      <div className="owl-item widpad">
  <div className="item">
    <div className="event-item">
      
      <div className="event-image">
        <img
          src="https://i.imgur.com/KbdAdtw.png"
          alt="best Product Shooting in andheri "
        />
       
      </div>
      <div className="event-content">
        <div className="event-title mb-30">
          <h3 className="title">EPARI SADASHIV JEWELLERS</h3>
          
        </div>
        
        <div style={{display:"flex",justifyContent:"center"}}>
<button href="#" className="custom-btn" onClick={() => setModalShow(true)}>
Enquiry Now
</button>
<MailPop
        show={modalShow}
        onHide={() => setModalShow(false)}
      /> 
</div>
      </div>
    </div>
  </div>
</div>
      </div>
      </SwiperSlide>
     
      <SwiperSlide>
        <div className="col-md-4">
      <div className="owl-item widpad">
  <div className="item">
    <div className="event-item">
      
      <div className="event-image">
        <img
          src="https://i.imgur.com/sBGoJ8C.png"
          alt="best Product Shooting in andheri "
        />
       
      </div>
      <div className="event-content">
        <div className="event-title mb-30">
          <h3 className="title">BHARAT MASALA</h3>
          
        </div>
        
        <div style={{display:"flex",justifyContent:"center"}}>
<button href="#" className="custom-btn" onClick={() => setModalShow(true)}>
Enquiry Now
</button>
<MailPop
        show={modalShow}
        onHide={() => setModalShow(false)}
      /> 
</div>
      </div>
    </div>
  </div>
</div>
      </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="col-md-4">
      <div className="owl-item widpad">
  <div className="item">
    <div className="event-item">
      
      <div className="event-image">
        <img
          src="https://i.imgur.com/5ZFkpJi.png"
          alt="best Product Shooting in andheri "
        />
       
      </div>
      <div className="event-content">
        <div className="event-title mb-30">
          <h3 className="title">NEWTOWN GRAND</h3>
          
        </div>
        
        <div style={{display:"flex",justifyContent:"center"}}>
<button href="#" className="custom-btn" onClick={() => setModalShow(true)}>
Enquiry Now
</button>
<MailPop
        show={modalShow}
        onHide={() => setModalShow(false)}
      /> 
</div>
      </div>
    </div>
  </div>
</div>
      </div>
      </SwiperSlide>
      <SwiperSlide>
        <div className="col-md-4">
      <div className="owl-item widpad">
  <div className="item">
    <div className="event-item">
      
      <div className="event-image">
        <img
          src="https://i.imgur.com/RX6YvFi.png"
          alt="best Product Shooting in andheri "
        />
       
      </div>
      <div className="event-content">
        <div className="event-title mb-30">
          <h3 className="title">MADHUKUNJ GOLD AGARBATTI</h3>
          
        </div>
        
        <div style={{display:"flex",justifyContent:"center"}}>
<button href="#" className="custom-btn" onClick={() => setModalShow(true)}>
Enquiry Now
</button>
<MailPop
        show={modalShow}
        onHide={() => setModalShow(false)}
      /> 
</div>
      </div>
    </div>
  </div>
</div>
      </div>
      </SwiperSlide>
 

       
  

      </Swiper>
      </div> 
    </div>
  )
}

export default ADDmaking
