import React, { useEffect, useState } from 'react';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Nav from 'react-bootstrap/Nav';
import Container from 'react-bootstrap/Container';
import "../css/hadder.css"
import logo from "../assets/logo.png"
import Aboutus from '../pages/Aboutus';
import { Link } from "react-router-dom";
import facebook from "../assets/facebook.png"
import instagram from "../assets/instagram.png"

const Hadder = () => {
  const [scrolling, setScrolling] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const isTop = window.scrollY < 100;
      if (isTop !== scrolling) {
        setScrolling(isTop);
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [scrolling]);
  const styles = {
    customToggler: {
      borderColor: 'transprent',
      color: 'white',
    },
    customTogglerIcon: {
      backgroundColor: 'white',
      width: '25px', // Adjust the width of the stripes
      height: '3px', // Adjust the height of the stripes
    },
  };
  return (
    <div>
           <div>
           <Navbar
        expand="lg"
        className={`fixed-top ${scrolling ? 'navbar-scrolled' : ''}`}
        style={{ backgroundColor: scrolling ? 'transparent' : 'black', transition: 'background-color 0.3s' }}
      >
      <Container >

        <Navbar.Brand href="#"><Link to="/"><img className='hadlogo' src={logo}/></Link></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" className="custom-toggler" style={styles.customToggler} />
        <Navbar.Collapse id="basic-navbar-nav" style={{marginLeft:"230px", color:"white"}}>
          <Nav className="me-auto">
            <Nav.Link  href="#"><Link className='black paddingrl' to="/">Home</Link></Nav.Link>
            <Nav.Link   href="#"><Link className='black paddingrl' to="/event-management-company-andheri">About us</Link></Nav.Link>
            <Nav.Link   href="#"><Link className='black paddingrl' to="/celebrity-management-company-andheri">Services</Link></Nav.Link>
            <Nav.Link   href="#"><Link className='black paddingrl' to="/top-event-management-company-andheri">Gallery</Link></Nav.Link>
            <Nav.Link   href="#"><Link className='black paddingrl' to="/best-celebrity-management-company-in-andheri">Contact Us</Link></Nav.Link>
          </Nav>
        </Navbar.Collapse>
        <Nav className="Social-media-icons">
                    <div className="SOCIAL">
     
     <a href="https://www.facebook.com/group.skworld">
     <img className='iconnn' src={facebook} ></img>
     </a>
     <a href="https://www.instagram.com/sk_world_pvt_ltd/">
     <img className='iconnn' src={instagram}></img>
     </a>
    
   </div>
                    </Nav>
      </Container>
    </Navbar>
    </div>
    </div>
  )
}

export default Hadder
