import React, { useRef, useLayoutEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import MailPop from "../components/Mailpopup"
import '../css/style.css'
import cel from "../assets/cel.JPG"

import cel2 from "../assets/product/c2.JPG"
import cel3 from "../assets/product/c3.JPG"
import cel4 from "../assets/product/c4.JPG"

import cel6 from "../assets/product/c6.JPG"
import cel7 from "../assets/product/c7.jpeg"
import cel8 from "../assets/product/c8.JPG"
// import required modules
import { Autoplay } from 'swiper/modules';

const Film = () => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <div>
          <Swiper
                slidesPerView={3}
                spaceBetween={30}
                autoplay={{
                  delay: 2500,
                  disableOnInteraction: false,
                }}
                style={{
                  height:"580px"
                }}
                breakpoints={{
                  0: {
                    slidesPerView: 1,
                    spaceBetween: 30,
                  },
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                  1000: {
                    slidesPerView: 4,
                    spaceBetween: 30,
                  },
                }}
                freeMode={true}
                pagination={{
                  clickable: true,
                }}
                modules={[Autoplay]}
                className="mySwiper"
              >
        <SwiperSlide>
        
      <div className="owl-item" >
  <div className="item">
    <div className="event-item">
      
      <div className="event-image">
        <img
          src={cel}
          alt="best celebrity management in andheri"
        />
       
      </div>
      <div className="event-content1" style={{backgroundColor:"white"}}>
        <div className="event-title mb-30">
          <h3 className="title" style={{color:"black"}}>Chitrangada Singh</h3>
          <p style={{textAlign:"center", color:"black"}}>(Actor)</p>
          
        </div>
       
       <div style={{display:"flex",justifyContent:"center"}}>
<button href="#" className="custom-btn" onClick={() => setModalShow(true)}>
Enquiry Now
</button>
<MailPop
        show={modalShow}
        onHide={() => setModalShow(false)}
      /> 
</div>
      </div>
    </div>
  </div>
</div>
     
        </SwiperSlide>
        <SwiperSlide> 
        
      <div className="owl-item" >
  <div className="item">
    <div className="event-item">
      
      <div className="event-image">
        <img
          src={cel2}
          alt="best celebrity management in andheri"
        />
       
      </div>
      <div className="event-content1" style={{backgroundColor:"white"}}>
        <div className="event-title mb-30">
          <h3 className="title" style={{color:"black"}}>Sharman Joshi</h3>
          <p style={{textAlign:"center" ,color:"black"}}>(Actor)</p>
          
        </div>
       
       <div style={{display:"flex",justifyContent:"center" }}>
<button href="#" className="custom-btn" onClick={() => setModalShow(true)}>
Enquiry Now
</button>
<MailPop
        show={modalShow}
        onHide={() => setModalShow(false)}
      /> 
</div>
      </div>
    </div>
  </div>
</div>
   
        </SwiperSlide>
 
<SwiperSlide>

      <div className="owl-item" >
  <div className="item">
    <div className="event-item">
      
      <div className="event-image" >
        <img 
          src={cel3}
          alt="best celebrity management in andheri"
        />
       
      </div>
      <div className="event-content1" style={{backgroundColor:"white"}}>
        <div className="event-title mb-30">
          <h3 className="title" style={{color:"black"}}>Badshah</h3>
          <p style={{textAlign:"center",color:"black"}}>(Singer)</p>
          
        </div>
       
       <div style={{display:"flex",justifyContent:"center"}}>
<button href="#" className="custom-btn" onClick={() => setModalShow(true)}>
Enquiry Now
</button>
<MailPop
        show={modalShow}
        onHide={() => setModalShow(false)}
      /> 
</div>
      </div>
    </div>
  </div>
</div>
</SwiperSlide>
<SwiperSlide>

      <div className="owl-item" >
  <div className="item">
    <div className="event-item">
      
      <div className="event-image" >
        <img 
          src={cel4}
          alt="best celebrity management in andheri"
        />
       
      </div>
      <div className="event-content1" style={{backgroundColor:"white"}}>
        <div className="event-title mb-30">
          <h3 className="title" style={{color:"black"}}>Himesh Reshammiya</h3>
          <p style={{textAlign:"center",color:"black"}}>(Singer)</p>
          
        </div>
       
       <div style={{display:"flex",justifyContent:"center"}}>
<button href="#" className="custom-btn" onClick={() => setModalShow(true)}>
Enquiry Now
</button>
<MailPop
        show={modalShow}
        onHide={() => setModalShow(false)}
      /> 
</div>
      </div>
    </div>
  </div>
</div>
</SwiperSlide>

<SwiperSlide>

      <div className="owl-item" >
  <div className="item">
    <div className="event-item">
      
      <div className="event-image">
        <img
          src={cel6}
          alt="best celebrity management in andheri"
        />
       
      </div>
      <div className="event-content1" style={{backgroundColor:"white"}}>
        <div className="event-title mb-30">
          <h3 className="title" style={{color:"black"}}>Sonu Nigam</h3>
          <p style={{textAlign:"center",color:"black"}}>(Singer)</p>
          
        </div>
       
       <div style={{display:"flex",justifyContent:"center" }}>
<button href="#" className="custom-btn" onClick={() => setModalShow(true)}>
Enquiry Now
</button>
<MailPop
        show={modalShow}
        onHide={() => setModalShow(false)}
      /> 
</div>
      </div>
    </div>
  </div>
</div>
</SwiperSlide>
<SwiperSlide>

      <div className="owl-item" >
  <div className="item">
    <div className="event-item">
      
      <div className="event-image">
        <img
          src={cel7}
          alt="Image_not_found"
        />
       
      </div>
      <div className="event-content1" style={{backgroundColor:"white"}}>
        <div className="event-title mb-30">
          <h3 className="title" style={{color:"black"}}>Huma Qureshi</h3>
          <p style={{textAlign:"center",color:"black"}}>(Actor)</p>
          
        </div>
       
       <div style={{display:"flex",justifyContent:"center" }}>
<button href="#" className="custom-btn" onClick={() => setModalShow(true)}>
Enquiry Now
</button>
<MailPop
        show={modalShow}
        onHide={() => setModalShow(false)}
      /> 
</div>
      </div>
    </div>
  </div>
</div>
</SwiperSlide>
      </Swiper>
    </div>
  )
}

export default Film
