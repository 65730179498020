import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import ban2 from "../assets/ban2.jpg"
// Import Swiper styles
import { Link } from "react-router-dom";
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import "../css/style.css"
import ban3 from "../assets/ban3.jpg"
import ban4 from "../assets/ban4.jpg"
import bannercel from "../assets/bannercel.png"
import bannerfil from "../assets/bannerfil.png"
import badsha from "../assets/badsha.png"
// import required modules
// import { Autoplay} from 'swiper/modules';

const Slider = () => {
  return (
    <div>
           <Swiper
        slidesPerView={1}
        spaceBetween={30}
        // autoplay={{
        //   delay: 3500,
        //   disableOnInteraction: false,
        // }}
        keyboard={{
          enabled: true,
        }}
       
        // modules={[Autoplay]}
        className="mySwiper"
      >
         <SwiperSlide>
        <div className="owl-item margintop" style={{ width: "100%" }}>
  <div
    className="item bannerbackgrounf"
    style= {{ backgroundImage: "" }}
  >
    <div className="overlay-black">
      <div className="container">
        <div className="slider-item-content">
        <Link to="/celebrity-management-company-andheri">
          <h2 className="big-text">CELEBRITY  <br/> <span className='fontsizeb' >MANAGEMENT</span></h2>
         </Link>
      
        </div>
      </div>
    </div>
  </div>
  <div>
    <img className='imagebanner1' src={badsha} alt="" />
    <div>
    <div className="slider-item-content texticon">
        <Link to="/celebrity-management-company-andheri">
          <h2 className="big-text">CELEBRITY  <br/> <span className='fontsizeb' >MANAGEMENT</span>
        <br></br>
         <buttion style={{fontSize:"20px",backgroundColor:"black",padding:"10px",fontweight:"200" }}>
          Enquiry Now
        </buttion></h2>
         </Link>
         
        </div>
     
    </div>
  </div>
</div>
        </SwiperSlide>
        {/* <SwiperSlide>
        <div className="owl-item margintop" style={{ width: "100%" }}>
  <div
    className="item"
    style= {{ backgroundImage: ""}} 
  >
    <div className="overlay-black">
      <div className="container">
        <div className="slider-item-content">
        
          <h2 className="big-text">MUSIC VIDEO  <br/><span className='fontsizeb'>PRODUCTION</span> </h2>
       
         
        </div>
      </div>
    </div>
  </div>
</div>
        </SwiperSlide> */} 
        <SwiperSlide>    <div className="owl-item margintop" style={{ width: "100%" }}>
  <div
    className="item bannerbackgrounf"
    style={{ backgroundImage: ""}}
  >
    <div className="overlay-black">
      <div className="container">
        <div className="slider-item-content">
        <Link to="/celebrity-management-company-andheri">
          <h2 className="big-text"> Ad Film <br/><span className='fontsizeb'>MAKING</span></h2>
          </Link>
        
         
        </div>
      </div>
    </div>
  </div>
</div>
</SwiperSlide>
        <SwiperSlide>
        <div className="owl-item margintop" style={{ width: "100%" }}>
  <div
    className="item bannerbackgrounf"
    style={{ backgroundImage: ""}}
  >
    <div className="overlay-black">
      <div className="container">
        <div className="slider-item-content">
        <Link to="/celebrity-management-company-andheri">
          <h2 className="big-text">EVENT <br/><span className='fontsizeb'>MANAGEMENT</span> </h2>
          </Link>
         
        </div>
      </div>
    </div>
  </div>
</div>
        </SwiperSlide>
        
      </Swiper>

    </div>
  )
}

export default Slider
