import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css/free-mode';
// Import Swiper styles
import 'swiper/css';

import '../css/style.css'
import { BiSolidQuoteAltRight } from 'react-icons/bi';
// import required modules
import { Autoplay , FreeMode} from 'swiper/modules';
const Testimonial = () => {
  return (
    <div>
    <div className="row">

     <Swiper
     style={{
       height:"360px"
     }}
           slidesPerView={3}
           spaceBetween={30}
           autoplay={{
             delay: 2500,
             disableOnInteraction: false,
           }}
           breakpoints={{
             0: {
               slidesPerView: 1,
               spaceBetween: 30,
             },
             768: {
               slidesPerView: 1,
               spaceBetween: 30,
             },
             1000: {
               slidesPerView: 1,
               spaceBetween: 30,
             },
           }}
           freeMode={true}
           pagination={{
             clickable: true,
           }}
           modules={[Autoplay,FreeMode]}
            
           className="mySwiper"
         >
  



 
 <SwiperSlide>
 <div className="owl-item cloned" style={{  }}>
  <div className="item text-center">
    <span className="quote-icon">
      <div className="fas fa-quote-right" style={{color:"#e0a231",fontSize:"50px"}} >
      <BiSolidQuoteAltRight/>
      </div>
    </span>
    <p className="clients-comment" style={{color:"white"}}>
      “Bring people together, or turn your passion into a business. Harmoni
      gives you everything you need to host your best event yet. lorem ipsum
      diamet. Bring people together, or turn your passion into a business.
      Harmoni gives you everything you need to host your best event yet.”
    </p>
    <div className="client-info">
      <h3 className="client-name">Jenni Hernandes</h3>
      <span className="client-sub-title">Graphic Designer</span>
    </div>
  </div>
</div>
 </SwiperSlide>

 
 <SwiperSlide>
 <div className="owl-item cloned" style={{  }}>
  <div className="item text-center">
    <span className="quote-icon">
      <div className="fas fa-quote-right" style={{color:"#e0a231",fontSize:"50px"}} >
      <BiSolidQuoteAltRight/>
      </div>
    </span>
    <p className="clients-comment" style={{color:"white"}}>
      “Bring people together, or turn your passion into a business. Harmoni
      gives you everything you need to host your best event yet. lorem ipsum
      diamet. Bring people together, or turn your passion into a business.
      Harmoni gives you everything you need to host your best event yet.”
    </p>
    <div className="client-info">
      <h3 className="client-name">Jenni Hernandes</h3>
      <span className="client-sub-title">Graphic Designer</span>
    </div>
  </div>
</div>
 </SwiperSlide>
  
 <SwiperSlide>
 <div className="owl-item cloned" style={{  }}>
  <div className="item text-center">
    <span className="quote-icon">
      <div className="fas fa-quote-right" style={{color:"#e0a231",fontSize:"50px"}} >
      <BiSolidQuoteAltRight/>
      </div>
    </span>
    <p className="clients-comment" style={{color:"white"}}>
      “Bring people together, or turn your passion into a business. Harmoni
      gives you everything you need to host your best event yet. lorem ipsum
      diamet. Bring people together, or turn your passion into a business.
      Harmoni gives you everything you need to host your best event yet.”
    </p>
    <div className="client-info">
      <h3 className="client-name">Jenni Hernandes</h3>
      <span className="client-sub-title">Graphic Designer</span>
    </div>
  </div>
</div>
 </SwiperSlide>
  
 <SwiperSlide>
 <div className="owl-item cloned" style={{  }}>
  <div className="item text-center">
    <span className="quote-icon">
      <div className="fas fa-quote-right" style={{color:"#e0a231",fontSize:"50px"}} >
      <BiSolidQuoteAltRight/>
      </div>
    </span>
    <p className="clients-comment" style={{color:"white"}}>
      “Bring people together, or turn your passion into a business. Harmoni
      gives you everything you need to host your best event yet. lorem ipsum
      diamet. Bring people together, or turn your passion into a business.
      Harmoni gives you everything you need to host your best event yet.”
    </p>
    <div className="client-info">
      <h3 className="client-name">Jenni Hernandes</h3>
      <span className="client-sub-title">Graphic Designer</span>
    </div>
  </div>
</div>
 </SwiperSlide>
  


 </Swiper>
 </div> 
</div>
  )
}

export default Testimonial
