import logo from "../assets/logo.png"
import g1 from "../assets/galley/g1.JPG"
import g2 from "../assets/galley/g2.JPG"
import g3 from "../assets/galley/g3.JPG"
import g4 from "../assets/galley/g4.JPG"
import g5 from "../assets/galley/g5.JPG"
import g6 from "../assets/galley/g6.JPG"
import g7 from "../assets/galley/g7.JPG"
import g8 from "../assets/galley/g8.JPG"
const GalleryItems = [
    {
      "title": "Image 1",
      "thumbnail": g1,
      "url": g1,
      "category": "image",
      "type": "image"
    },
    {
     
      
      "videoUrl": "https://youtu.be/dtLXZpwQR5U?si=9x9xwmxy3JGkPPoA",
      "category": "video",
      "type": "video"
    },
    // Add more items as needed
  ];

  export default GalleryItems;