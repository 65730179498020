import React from 'react'
import Hadder from '../components/Hadder'
import Footer from '../components/footer'
import {MdHouseSiding,MdGroups} from "react-icons/md";
import { BsBalloonFill } from 'react-icons/bs';
import { BiPhoneCall } from 'react-icons/bi';
import {AiTwotoneBulb} from "react-icons/ai";
import "../css/Aboutus.css"
import{SlCalender} from "react-icons/sl";
import { BiSolidQuoteAltRight } from 'react-icons/bi';
import { FaHandshake,FaGlassCheers } from 'react-icons/fa';
import Testimonial from '../components/testimonial';
import ban2 from "../assets/ban2.jpg"
import Sociallink from "../components/Sociallink"
import { MetaTags } from 'react-meta-tags';
import Eventglimps from '../components/Eventglimps';
import WeWorjk from '../components/WeWorjk';
const Aboutus = () => {
  return (
    <div>
       <MetaTags>
        <title> India's Best Celebrity Management Agency in Mumbai | SK World</title>
        <meta title=" India's Best Celebrity Management Agency in Mumbai | SK World" />
        <meta name="description" content=" India's Best Celebrity Management Agency in Mumbai SK World provides  the stars in your events with our unrivaled services across India." />
        <meta name="keywords" content="indias-best-celebrity-management-agency-mumbai,event-management-company-andheri,celebrity-management-company-andheri,top-event-management-company-andheri,best-celebrity-management-company-in-andheri" />
        <link rel="canonical" href="https://celebritydecorator.com/" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content=" India's Best Celebrity Management Agency in Mumbai | SK World" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://celebritydecorator.com/" />
        <meta property="og:description" content=" India's Best Celebrity Management Agency in Mumbai SK World provides  the stars in your events with our unrivaled services across India." />
        <meta property="og:image" content="https://skworld.in/static/media/logo.0a02f996c9ddeb40558c.png" />
      </MetaTags>
      <Hadder></Hadder>
      <section id="breadcrumb-section" className="breadcrumb-section clearfix margintop">
  <div
    className="jarallax"
    style={{ backgroundImage: "none", zIndex: 0 , backgroundImage: "radial-gradient(circle, #808080, #5f5f5f, #3f3f3f, #222222, #000000)", }}
    
  >
    <div className="overlay-black">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-12 col-sm-12">
            {/* breadcrumb-title - start */}
            <div className="breadcrumb-title text-center mb-50">
              <span className="sub-title">all you need to know</span>
              
              <h1 className="big-title">
                <strong>India's Best Celebrity Management Agency in Mumbai</strong> 
              </h1>
            </div>
            {/* breadcrumb-title - end */}
            {/* breadcrumb-list - start */}
            <div className="breadcrumb-list">
              {/* <ul>
                <li className="breadcrumb-item">
                  <a href="index-1.html" className="breadcrumb-link">
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  about us
                </li>
              </ul> */}
            </div>
            {/* breadcrumb-list - end */}
          </div>
        </div>
      </div>
    </div>
    <div
      id="jarallax-container-0"
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        overflow: "hidden",
        pointerEvents: "none",
        zIndex: -100
      }}
    >
      <div
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
    
          position: "fixed",
          top: 0,
          left: 0,
          width: "1409.6px",
          height: "553.66px",
          overflow: "hidden",
          pointerEvents: "none",
          marginTop: "49.17px",
         
        }}
      />
    </div>
  </div>
</section>
{/* <section
  id="our-management-section"
  className="our-management-section bg-gray-light sec-ptb-100 clearfix"
>
  <div className="container">
    <div className="row"> */}
      {/* section-title - start */}
      {/* <div className="col-lg-4 col-md-12 col-sm-12">
        <div className="section-title text-left mb-50 sr-fade1">
          <small className="sub-title">we are SK World</small>
          <h2 className="big-title">
            <strong>No.1</strong> Celebrity Management
          </h2> */}
          {/* <a href="#!" className="custom-btn">
            get started!
          </a> */}
        {/* </div>
      </div> */}
      {/* section-title - end */}
      {/* <div className="col-lg-8 col-md-12 col-sm-12">
        <div className="row"> */}
          {/* management-item - start */}
          {/* <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="management-item sr-fade2">
              <div className="item-title">
                <h3 className="title-text" style={{color:"white"}}>our mission</h3>
              </div>
              <p className="black-color mb-30" style={{color:"white",textAlign:'justify'}}>
              "At SK World, our mission is to be the guiding force behind India's brightest stars, empowering them to reach their fullest potential in the world of entertainment."



              </p>
              <p className="black-color m-0" style={{color:"white",textAlign:'justify'}}>
                <strong>
                  <i>
                  We are committed to providing comprehensive, personalised and innovative Celebrity Management Services that propel careers to new heights. Our unwavering dedication to our clients' success and well-being drives us to build lasting partnerships and create opportunities that shape the future of the entertainment industry.
                  </i>
                </strong>
              </p>
            </div>
          </div> */}
          {/* management-item - end */}
          {/* management-item - start */}
          {/* <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="management-item sr-fade3">
              <div className="item-title">
                <h3 className="title-text" style={{color:"white"}}>our vission</h3>
              </div>
              <p className="black-color mb-30" style={{color:"white"}} >
              “Our vision is to be the premier driving force for artistic excellence and industry transformation. We aspire to set new standards in the realm of celebrity management, continually adapting to the ever-evolving landscape of entertainment. "

              </p>
              <p className="black-color m-0" style={{color:"white"}}>
                <strong>
                  <i>
                  SK World envisions a future where our clients not only shine brightly on stage and screen but also make a profound impact on society through their talent and influence. We aim to foster an environment of creativity, diversity, and innovation, where every talent we represent becomes an iconic symbol of inspiration and achievement.
                  </i>
                </strong>
              </p>
            </div>
          </div> */}
          {/* management-item - end */}
        {/* </div>
      </div>
    </div>
  </div>
</section> */}
<section id="award-section" style={{backgroundColor:"white"}} className="award-section sec-ptb-100 clearfix">
  <div className="container">
    <div className="row" >
      {/* company-age - start */}
      <div className="col-lg-6 col-md-12 col-sm-12">
        <div
          className="company-age sr-fade1"
          style={{ backgroundImage: `url(${ban2})` }}
        >
          
        </div>
      </div>
      {/* company-age - end */}
      <div className="col-lg-6 col-md-12 col-sm-12">
        {/* section-title - start */}
        <div className="section-title text-left margtop10 sr-fade1">
          <small className="sub-title" >SK World</small>
          <h2 className="big-title" style={{color:"black"}}>
            About  <strong>SK World</strong>
          </h2>
        </div>
        {/* section-title - end */}
        {/* awaed-item-area - start */}
        <p style={{ textAlign:"justify"}}>
  SK WORLD ENTERTAINMENT is a fully-fledged Entertainment company with verticals in LIVE EVENTS, CORPORATE EVENTS, CELEBRITY MANAGEMENT, and ADVERTISING.

SK WORLD ENTERTAINMENT is one of the TOP celebrity management companies in INDIA
  </p>
  <p style={{ textAlign:"justify"}}>
  Sunil Kumar (CMD) is a Reputed name in Regional Film Industry, he comes with around 12 years of acting experience as a lead in Odia, Bengali & Marathi films.
  </p>
  <p style={{ textAlign:"justify"}}>
  Sunil Kumar started this venture with a vision to mobilize his experience in the field of Events, Advertising, Talent Management etc and share it with others. It is his Association with the film industry of bridging the gap between the client requirement of any Artist / Celebrity & providing them the same at the best commercials propelled him towards steering on his own.
  </p>
        {/* awaed-item-area - end */}
      </div>
    </div>
  </div>
</section>
{/* <section
  id="service-section"
  className="service-section sec-ptb-100 bg-gray-light clearfix"
>
  <div className="container">
    <div className="row">
      <div className="col-lg-6">
        <div className="section-title mb-50 sr-fade1">
          <span className="line-style" />
          <small className="sub-title">why choose us</small>
          <h2 className="big-title">
            Sk World <strong>Advantages</strong>
          </h2>
        </div>
      </div>
      <div className="col-lg-6">
       
      </div>
    </div>
    <div className="service-wrapper sr-fade1">
      <ul>
        <li>
        <a href="#">
            <span className="icon">
              <div className="flaticon-handshake fontsize30" >
             <FaHandshake/>
              </div>

            </span>
            <strong className="service-title">friendly team</strong>
            <small className="service-sub-title">More than 200 teams</small>
         </a>
        </li>
        <li>
          <a href="#">
            <span className="icon">
              <div className="flaticon-event-date-and-time-symbol fontsize30" >
              <SlCalender/>
                </div>
            </span>
            <strong className="service-title">Unique Scenario</strong>
            <small className="service-sub-title">perfact venues</small>
          </a>
        </li>
        <li>
          <a href="#">
            <span className="icon">
              <div className="flaticon-cheers fontsize30" >
              <FaGlassCheers/>
              </div>
            </span>
            <strong className="service-title">Unique Scenario</strong>
            <small className="service-sub-title">
              we thinking out of the box
            </small>
          </a>
        </li>
       
        <li>
          <a href="#">
            <span className="icon">
              <div className="flaticon-speech-bubble fontsize30" >
                <BiPhoneCall/>
                </div>
            </span>
            <strong className="service-title">24/7 hours support</strong>
            <small className="service-sub-title">anitime anywhere</small>
          </a>
        </li>
        <li>
          <a href="#">
            <span className="icon">
              <div className="flaticon-clown-hat fontsize30">
              <MdHouseSiding/>
              </div>
            </span>
            <strong className="service-title">Unforgetable Times</strong>
            <small className="service-sub-title">More than 200 teams</small>
          </a>
        </li>
        <li>
          <a href="#">
            <span className="icon">
              <div className="flaticon-light-bulb fontsize30" >
              <AiTwotoneBulb/>
                </div>
            </span>
            <strong className="service-title">Briliant Idea</strong>
            <small className="service-sub-title">
              We thinking out of the box
            </small>
          </a>
        </li>
        <li>
          <a href="#">
            <span className="icon">
              <div className="flaticon-event-date-and-time-symbol fontsize30" >
              <SlCalender/>
                </div>
            </span>
            <strong className="service-title">Unique Scenario</strong>
            <small className="service-sub-title">perfact venues</small>
          </a>
        </li>
        <li>
          <a href="#">
            <span className="icon">
              <div className="flaticon-group fontsize30" >
                <MdGroups/>
                </div>
            </span>
            <strong className="service-title">Best Management</strong>
            <small className="service-sub-title">anytime anywhere</small>
          </a>
        </li>
      </ul>
    </div>
  </div>
</section> */}
<section
  id="testimonial5-section"
  className="testimonial5-section sec-ptb-100 clearfix"
  style={{backgroundColor:"black"}}
>
  <div className="container">
    {/* section-title - start */}
    <div className="section-title text-center mb-80 sr-fade1">
      <small className="sub-title">testimonials</small>
      <h2 className="big-title">
        clients <strong>testimonial</strong>
      </h2>
    </div>
    {/* section-title - end */}
    {/* testimonial5-carousel - start */}
    <div
      id="testimonial5-carousel"
      className="testimonial5-carousel sr-fade2 owl-carousel owl-theme owl-loaded owl-drag"
    >
   <Testimonial></Testimonial>
      
    </div>
    {/* testimonial5-carousel - end */}
  </div>
</section>




<Sociallink></Sociallink>
      <Footer></Footer>
    </div>
  )
}

export default Aboutus


