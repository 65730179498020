import React from 'react'
import logo from "../assets/logo.png"
import { Link } from 'react-router-dom'
import Hadder from './Hadder'
import Footer from './footer'
const Error = () => {
  return (
    <div>
      <Hadder></Hadder>
  <section
  id="error-section"
  className="error-section sec-ptb-100 bg-gray-light clearfix pad50"
>
  <div className="container">
    <div className="row justify-content-center">
      {/* error-content - start */}
      <div className="col-lg-4 col-md-6 col-sm-12">
        <div className='errorlogo'>
          <img className='pad40' src={logo}/>
          </div>
      </div>
      {/* error-content - end */}
      {/* error-content - start */}
      <div className="col-lg-4 col-md-6 col-sm-12">
        <div className="error-content">
          <h2 style={{color:"white"}}>404</h2>
          <h3 style={{color:"white"}}>error - page not found</h3>
          <p className="mb-30" style={{color:"white"}}>
          "Lost in cyberspace? Our apologies, but the page you're seeking has ventured into the digital abyss."
          </p>
          <a href="#" className="custom-btn">
            <Link to="/" style={{color:"white"}}>
            go back to home
            </Link>
          </a>
        </div>
      </div>
      {/* error-content - end */}
    </div>
  </div>
</section>
   <Footer></Footer>
    </div>
  )
}

export default Error
