
import Hadder from '../components/Hadder'
import Footer from '../components/footer'
import ban2 from "../assets/ban2.jpg"
import Sociallink from "../components/Sociallink"
import Button from 'react-bootstrap/Button';
import MailPop from "../components/Mailpopup"
import cel from "../assets/cel.JPG"
import movie from "../assets/product/movie2.jpg"
import React, { useRef, useLayoutEffect, useState } from "react";
import { MetaTags } from 'react-meta-tags';
import { AiOutlineArrowRight } from 'react-icons/ai';
const Services = () => {
  const [modalShow, setModalShow] = React.useState(false);
  return (
    <div>
      <Hadder></Hadder>
      <MetaTags>
        <title>Celebrity Management Company in Andheri | SK World</title>
        <meta title="Celebrity Management Company in Andheri | SK World" />
        <meta name="description" content="Elevate Your Stardom with Our Celebrity Management Company in Andheri. Discover unparalleled talent representation and promotion tailored to your needs. " />
        <meta name="keywords" content="indias-best-celebrity-management-agency-mumbai,event-management-company-andheri,celebrity-management-company-andheri,top-event-management-company-andheri,best-celebrity-management-company-in-andheri" />
        <link rel="canonical" href="https://skworld.in/celebrity-management-company-andheri" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Celebrity Management Company in Andheri | SK World" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://skworld.in/" />
        <meta property="og:description" content="Elevate Your Stardom with Our Celebrity Management Company in Andheri. Discover unparalleled talent representation and promotion tailored to your needs. " />
        <meta property="og:image" content="https://skworld.in/static/media/logo.0a02f996c9ddeb40558c.png" />
      </MetaTags>
      <section id="breadcrumb-section" className="breadcrumb-section clearfix margintop">
  <div
    className="jarallax"
    style={{ backgroundImage: "none", zIndex: 0 ,  backgroundImage: "radial-gradient(circle, #808080, #5f5f5f, #3f3f3f, #222222, #000000)" , }}
    
  >
    <div className="overlay-black">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-12 col-sm-12">
            {/* breadcrumb-title - start */}
            <div className="breadcrumb-title text-center mb-50">
              <span className="sub-title">all you need to know</span>
             
              <h1 className="big-title">
                <strong>Celebrity Management Company in Andheri</strong>
              </h1>
            </div>
            {/* breadcrumb-title - end */}
            {/* breadcrumb-list - start */}
            <div className="breadcrumb-list">
              <ul>
                <li className="breadcrumb-item">
                  <a href="#" className="breadcrumb-link">
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Services
                </li>
              </ul>
            </div>
            {/* breadcrumb-list - end */}
          </div>
        </div>
      </div>
    </div>
    <div
      id="jarallax-container-0"
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        overflow: "hidden",
        pointerEvents: "none",
        zIndex: -100
      }}
    >
      <div
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
        backgroundColor:"black",
          position: "fixed",
          top: 0,
          left: 0,
          width: "1409.6px",
          height: "553.66px",
          overflow: "hidden",
          pointerEvents: "none",
          marginTop: "49.17px",
         
        }}
      />
    </div>
  </div>
</section>
<section id="service-section" className="service-section sec-ptb-100 clearfix">
  <div className="container">
    {/* service-wrapper - start */}
    <div className="service-wrapper">
      {/* service-item - start */}
      <div className="service-item clearfix">
        <div className="row">
          <div className="col-md-6"> <div className="  float-left">
          <div className="big-image">
            <img className='height440'
              src="https://img.freepik.com/free-photo/happy-people-celebrating-having-fun_23-2147651892.jpg?t=st=1692193515~exp=1692194115~hmac=9e7c84834f2f229c02404badfd072a6ea68a99b4f759de0d3df674d245745c60"
              alt="best event management andheri"
            />
          </div>
          
        </div></div>
          <div className="col-md-6" style={{backgroundColor:"black"}}> <div className="service-content float-right">
          <div className="service-title mb-30">
            <h2 className="title-text">
              <strong className='colorwhite' style={{color:"white"}} >Event</strong>
            </h2>
           
          </div>
          <p className="service-description black-color mb-30 colorwhite" style={{textAlign:"justify",color:"white"}}  >
          Celebrity Management Company in Andheri takes the concept of events to a whole new level by understanding that every event is an opportunity to create a unique and unforgettable experience, whether it's a star-studded film premiere, a corporate conference, or product shoot.
          </p>
          <p className="service-description black-color mb-30 colorwhite" style={{textAlign:"justify",color:"white"}} >
          SK World approaches event management with an unwavering commitment to precision and creativity as they see events as a canvas on which they paint stories, where every detail matters, from the choice of venue and décor to the entertainment and logistics. 
          </p>
          
          <div className="text-left">
           <Button href="#" className="custom-btn" onClick={() => setModalShow(true)}>
Enquiry Now
</Button>
<MailPop
        show={modalShow}
        onHide={() => setModalShow(false)}
      /> 
           
          </div>
        </div></div>
        </div>
       
       
      </div>
      {/* service-item - end */}
      {/* service-item - start */}
      <div className="service-item clearfix">
        <div className="row">
        <div className="col-md-6 order-md-1 order-2" style={{backgroundColor:"black"}}>
          <div className="service-content float-left">
          <div className="service-title mb-30">
            <h2 className="title-text">
              <strong className='colorwhite'style={{color:"white"}}> Ad Making</strong>
            </h2>
           
          </div>
          <p className="service-description black-color mb-30 colorwhite" style={{textAlign:"justify",color:"white"}}>
          Celebrity Management Company in Andheri is known for crafting unforgettable experiences, but their creative prowess extends beyond just events. They also excel in the art of ad making. In a landscape where visuals and storytelling are paramount, this versatile company leverages its creativity and attention to detail to produce compelling advertisements that captivate audiences.
          </p>
          <p className="service-description black-color mb-30 colorwhite" style={{textAlign:"justify",color:"white"}}>
          With a team of seasoned professionals who understand the power of storytelling, SK World creates advertisements that not only showcase products or services but also evoke emotions and leave a lasting impression. They know that every ad is an opportunity to connect with the target audience and convey a message that resonates.
          </p>
          
          <div className="text-left">
           <Button href="#" className="custom-btn" onClick={() => setModalShow(true)}>
Enquiry Now
</Button>
<MailPop
        show={modalShow}
        onHide={() => setModalShow(false)}
      /> 
           
          </div>
        </div>
          </div>
          <div className="col-md-6 order-md-2 order-1">
          <div className=" float-right">
        <div className="big-image">
            <img className='height440'
              src="https://cc-prod.scene7.com/is/image/CCProdAuthor/product-photography_P3a_720x560?$pjpeg$&jpegSize=200&wid=720"
              alt=" best Ad Making making in andheri"
            />
          </div>
          
        </div>
          </div>
         
        </div>
       
       
      </div>
      {/* service-item - end */}
      {/* service-item - start */}
      <div className="service-item clearfix">
        <div className="row">
          <div className="col-md-6">
          <div className=" float-left">
        <div className="big-image">
            <img className='height440'
              src={cel}
              alt="best Celebrity Management in andheri"
            />
          </div>
         
        </div>
          </div>
          <div className="col-md-6" style={{backgroundColor:"black"}}>
          <div className="service-content float-right">
          <div className="service-title mb-30">
            <h2 className="title-text">
               <strong className='colorwhite' style={{color:"white"}}>Celebrity Management</strong>
            </h2>
           
          </div>
          <p className="service-description black-color mb-30 colorwhite" style={{textAlign:"justify",color:"white"}}>
          Celebrity Management Company in Andheri  stands out not only as a masterful Event Management and Ad Making Agency but also as a key player in Celebrity Management. They understand that celebrities are not just faces but storytellers and influencers who can shape perceptions and leave a profound impact on audiences. elites sed diam nonummy nibh the euismod tincidunt ut laoreet dolore
            magna aliquam erat volutpat insignia the consectuer adipiscing elit.
          </p>
          <p className="service-description black-color mb-30 colorwhite" style={{textAlign:"justify",color:"white"}}>
          With their extensive network and deep understanding of the entertainment industry, SK World takes Celebrity Management to the next level. They work closely with artists, actors, musicians, and influencers to not only secure career-defining opportunities but also to craft a holistic image and brand that resonates with their audience.
          </p>
         
          <div className="text-left">
           <Button href="#" className="custom-btn" onClick={() => setModalShow(true)}>
Enquiry Now
</Button>
<MailPop
        show={modalShow}
        onHide={() => setModalShow(false)}
      /> 
           
          </div>
        </div>
          </div>
        </div>
        
        
      </div>
      {/* service-item - end */}
      {/* service-item - start */}
      <div className="service-item clearfix">
        <div className="row">
        <div className="col-md-6 order-md-1 order-2" style={{backgroundColor:"black"}}>
          <div className="service-content float-left">
          <div className="service-title mb-30">
            <h2 className="title-text">
             <strong className='colorwhite'style={{color:"white"}}>Movies</strong>
            </h2>
          
          </div>
          <p className="service-description black-color mb-30 colorwhite" style={{textAlign:"justify",color:"white"}}>
          Celebrity Management Company in Andheri excels in Event management, Ad Making and Celebrity Management, Movies hold a special place as the ultimate storytelling medium. Just as this dynamic company creates memorable experiences through events, crafts captivating advertisements, and shapes the careers of celebrities, they also recognize the significance of the silver screen as a platform for storytelling on a grand scale.
          </p>
          <p className="service-description black-color mb-30 colorwhite" style={{textAlign:"justify",color:"white"}} >
          Movies, in the hands of skilled filmmakers and creative talents, have the power to transport audiences to different worlds, evoke emotions, and convey powerful messages. SK World understands this potential and often collaborates with renowned directors, producers and actors to bring cinematic experiences to life.

          </p>
         
          <div className="text-left">
           <Button href="#" className="custom-btn" onClick={() => setModalShow(true)}>
Enquiry Now
</Button>
<MailPop
        show={modalShow}
        onHide={() => setModalShow(false)}
      /> 
           
          </div>
        </div>
          </div>
          <div className="col-md-6 order-md-2 order-1">
          <div className=" float-right">
        <div className="big-image">
            <img className='height440'
              src={movie}
              alt="best movies in andheri"
            />
          </div>
         
        </div>
          </div>
         
        </div>
      
       
      </div>
      {/* service-item - end */}
    </div>
    {/* service-wrapper - end */}
  </div>
</section>
<Sociallink></Sociallink>
      <Footer></Footer>
    </div>
  )
}

export default Services
