

import Gallery from "react-photo-gallery";
import Carousel, { Modal, ModalGateway } from "react-images";
import { photos } from "../components/Photos";
import Hadder from "../components/Hadder";
import Footer from "../components/footer";
import React, { useState } from 'react';
import Lightbox from 'react-image-lightbox';
import 'react-image-lightbox/style.css';

import ReactPlayer from 'react-player'; 
import ban2 from "../assets/ban2.jpg"
import { MetaTags } from 'react-meta-tags';
import { Link } from 'react-router-dom';
import GalleryItems from "../pages/GalleryItems"

const Gallery1 = () => {
  const [filter, setFilter] = useState('all');
  const [lightboxOpen, setLightboxOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);

  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
  };

  const openLightbox = (index) => {
    setLightboxIndex(index);
    setLightboxOpen(true);
  };

  const closeLightbox = () => {
    setLightboxOpen(false);
  };
    return (
      <div>
        <Hadder></Hadder>

        <section id="breadcrumb-section" className="breadcrumb-section clearfix margintop" >
  <div
    className="jarallax"
    style={{ backgroundImage: "none", zIndex: 0 ,  backgroundImage: "radial-gradient(circle, #808080, #5f5f5f, #3f3f3f, #222222, #000000)" , }}
    
  >
    <div className="overlay-black">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-lg-6 col-md-12 col-sm-12">
            {/* breadcrumb-title - start */}
            <div className="breadcrumb-title text-center mb-50">
              <span className="sub-title">all you need to know</span>

              <h1 className="big-title">
                <strong>Top Event Management Company in Andheri</strong> 
              </h1>
            </div>
            {/* breadcrumb-title - end */}
            {/* breadcrumb-list - start */}
            <div className="breadcrumb-list">
              <ul>
                <li className="breadcrumb-item">
                  <a href="#" className="breadcrumb-link">
                    Home
                  </a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Gallery
                </li>
              </ul>
            </div>
            {/* breadcrumb-list - end */}
          </div>
        </div>
      </div>
    </div>
    <div
      id="jarallax-container-0"
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        overflow: "hidden",
        pointerEvents: "none",
        zIndex: -100
      }}
    >
      <div
        style={{
          backgroundPosition: "50% 50%",
          backgroundSize: "cover",
          backgroundRepeat: "no-repeat",
    
          position: "fixed",
          top: 0,
          left: 0,
          width: "1409.6px",
          height: "553.66px",
          overflow: "hidden",
          pointerEvents: "none",
          marginTop: "49.17px",
         
        }}
      />
    </div>
  </div>
</section>

          <div className='textcent'>
      <div className='link-groups'>
        <button
          className={`about-btn custom-btn ${filter === 'all' && 'active'}`}
          style={{ margin: '5px' }}
          onClick={() => handleFilterChange('all')}
        >
          All
        </button>
        <button
          className={`about-btn custom-btn ${filter === 'image' && 'active'}`}
          style={{ margin: '5px' }}
          onClick={() => handleFilterChange('image')}
        >
          Image
        </button>
        <button
          className={`about-btn custom-btn ${filter === 'video' && 'active'}`}
          style={{ margin: '5px' }}
          onClick={() => handleFilterChange('video')}
        >
          Video
        </button>
      </div>

      <div className='gallery'>
        {GalleryItems.map((item) => (
          (filter === 'all' || item.category === filter) && (
           
              <img src={item.thumbnail} alt={item.title} />
           
          )
        ))}
      </div>
    </div>
    <div className='gallery'>
        {GalleryItems.map((item, index) => (
          (filter === 'all' || item.category === filter) && (
            <div className="">

            
            <div  key={index} className="gallery-item " onClick={() => openLightbox(index)}>
              {item.type === 'image' ? (
                <img src={item.thumbnail} alt={item.title} />
              ) : item.type === 'video' ? (
                <div className="video-thumbnail" style={{width:"100%"}}>
                  {/* Use a video player library to render the video */}
                  <ReactPlayer url={item.videoUrl}
                    width="100%"
                    height="100%"
                    controls />
                </div>
              ) : null}
            </div>
            </div>
          )
        ))}
      </div>

      {/* Lightbox for both images and videos */}
      {lightboxOpen && (
        <Lightbox
          mainSrc={GalleryItems[lightboxIndex].url}
          onCloseRequest={closeLightbox}
        />
      )}


        <Footer></Footer>
      </div>
    );
}

export default Gallery1
