import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

import { FreeMode, Pagination } from 'swiper/modules';

const Eventglimps = () => {
  return (
    <>
    <Swiper
    //   slidesPerView={3}
    //   spaceBetween={30}
      freeMode={true}
      pagination={{
        clickable: true,
      }}
      breakpoints={{
        0: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        1000: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      }}
      modules={[FreeMode, Pagination]}
      className="mySwiper"
    >
      <SwiperSlide> <div className=" paddin-bott">
      <img src="https://i.imgur.com/lXOAWLI.png" alt="" />
      <h6 style={{textAlign:"center", color:"white", padding:"10px"}}> SAN FRANCISCO</h6>
    </div></SwiperSlide>
      <SwiperSlide> <div className=" paddin-bott">
      <img src="https://i.imgur.com/xxNG8U3.png" alt="" />
      <h6 style={{textAlign:"center", color:"white", padding:"10px"}}>
      KALINGANAGAR MAHOTSAV (ODISHA)
      </h6>
    </div></SwiperSlide>
      <SwiperSlide> <div className=" paddin-bott">
      <img src="https://i.imgur.com/CHmeD92.png" alt="" />
      <h6 style={{textAlign:"center", color:"white" , padding:"10px"}}>UCPAC, NEW JERSEY (USA)</h6>
    </div></SwiperSlide>
      <SwiperSlide> <div className=" paddin-bott">
      <img src="https://i.imgur.com/F4pJZmN.png" alt="" />
      <h6 style={{textAlign:"center", color:"white", padding:"10px"}}>HITACHI, DEALERS MEET AND AWARD NIGHT, THE LALIT</h6>
    </div></SwiperSlide>
      <SwiperSlide><div className=" paddin-bott">
      <img src="https://i.imgur.com/hMq3Jxw.png" alt="" />
      <h6 style={{textAlign:"center", color:"white", padding:"10px"}}>BBOTT CONFERENCE, THE LALIT I GOA</h6>
    </div></SwiperSlide>
      <SwiperSlide> <div className=" paddin-bott">
      <img src="https://i.imgur.com/gMuF5Ae.png" alt="" />
    </div></SwiperSlide>
      <SwiperSlide>  <div className=" paddin-bott">
      <img src="https://i.imgur.com/IvPOKws.jpg" alt="" />
      <h6 style={{textAlign:"center", color:"white", padding:"10px"}}>ASTRAZENECA, CONFERENCE AND AWARD NIGHT, BANGALORE</h6>
    </div></SwiperSlide>
      <SwiperSlide> <div className=" paddin-bott">
      <img src="https://i.imgur.com/Ryr7E0Q.jpg" alt="" />
      <h6 style={{textAlign:"center", color:"white", padding:"10px"}}>
      VIVO VBA MEET - JAIPUR
      </h6>
    </div></SwiperSlide>
     
    </Swiper>
  </>
  )
}

export default Eventglimps
