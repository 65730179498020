import React, {useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

import $ from "jquery";
import Form from 'react-bootstrap/Form';

const MailPop = (props) => {

    const {onHide} = props;
    // email
    const [custName, setCustName] = useState("");
    const [custEmail, setCustEmail] = useState("");
    const [custContact, setCustContact] = useState("");
    const [custSubject, setCustSubject] = useState("");
    const [custMessage, setCustMessage] = useState("");
  //////////
  const Appoinment = (e) => {
   
    var body =
    '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#eb6e14">Sk World Enquiry</h2><p>Hello</p><p>Thank you for your interest in our products/services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left;color:#0a500a"><strong>Name:</strong></td><td style="text-align:left">' + custName + '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>Email Id:</strong></td><td style="text-align:left">' + custEmail + '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>Contact No:</strong></td><td style="text-align:left">' + custContact + '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>subject:</strong></td><td style="text-align:left">' + custSubject + '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>Message:</strong></td><td style="text-align:left">' + custMessage + '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at sk world</p></div></body></html>';
    // console.log(custServices)
    $.post(
      "https://skdm.in/server/v1/send_lead_mail.php",
      {
        toEmail: "skworldgroup@gmail.com",
        fromEmail: "skdmlead@gmail.com",
        bccMail: "skdmlead@gmail.com",
        mailSubject: "New Customer Lead",
        mailBody: body,
        accountName: "skworld",
        accountLeadSource: "https://skdm.in/",
        accountLeadName: custName,
        // accountLeadEmail: custEmail,
      },
      function (dataa, status) {
        console.log("data :" + dataa);
        // console.log("name:" + custName);
        // console.log("name:" + custEmail);
        // console.log("name:" + custCheckIn);
      }
    );
  
    alert("Your Form has Submitted Our team will contact with You  soon.");
    e.preventDefault(); 
    onHide();
    return false;
  };
   
    
  return (
    <>
    <div className='modelpop'>
  <Modal {...props}>
        <Modal.Header closeButton>
          <Modal.Title style={{color:"black"}}> Enquiry Form   </Modal.Title>
        </Modal.Header>
        <Modal.Body> 
        <Form >
    <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label style={{color:"black"}} >Name</Form.Label>
        <Form.Control type="text" placeholder="Full Name"   onChange={(e) => setCustName(e.target.value)} />
      </Form.Group>
      
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label style={{color:"black"}} >Email address</Form.Label>
        <Form.Control type="email" onChange={(e) => setCustEmail(e.target.value)} placeholder="name@example.com" />
      </Form.Group>
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label style={{color:"black"}} >Mobile No</Form.Label>
        <Form.Control type="number" placeholder="123456789" onChange={(e) => setCustContact(e.target.value)} />
      </Form.Group>
      
      <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
        <Form.Label style={{color:"black"}} >Subject</Form.Label>
        <Form.Control type="text" placeholder="Subject" onChange={(e) =>setCustSubject(e.target.value)} />
      </Form.Group>
     
      <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
        <Form.Label style={{color:"black"}} >Small Details</Form.Label>
        <Form.Control as="textarea" rows={3}  onChange={(e) => setCustMessage(e.target.value)}  />
      </Form.Group>
     
      <Button variant="primary" type="submit" onClick={Appoinment} style={{backgroundColor:"#e0a231"}}>
        Submit
      </Button>
    </Form>
    </Modal.Body>
     
      </Modal>
      </div>
    </>
  );
};

export default MailPop;
