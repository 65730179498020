import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/pagination';

import { FreeMode, Pagination } from 'swiper/modules';

const Team = () => {
  return (
    <>
    <Swiper
    //   slidesPerView={3}
    //   spaceBetween={30}
      freeMode={true}
      pagination={{
        clickable: true,
      }}
      breakpoints={{
        0: {
          slidesPerView: 1,
          spaceBetween: 30,
        },
        768: {
          slidesPerView: 2,
          spaceBetween: 30,
        },
        1000: {
          slidesPerView: 3,
          spaceBetween: 30,
        },
      }}
      modules={[FreeMode, Pagination]}
      className="mySwiper"
    >
      <SwiperSlide> <div className=" paddin-bott">
        <div className='image-center'>
        <img className='imageradus'  src="https://i.pinimg.com/736x/de/59/4e/de594ec09881da3fa66d98384a3c72ff.jpg" alt=""  />  
        </div>
     
      <h6 style={{textAlign:"center", color:"white", padding:"10px"}}> Rahul</h6>
      <h5 style={{color:"white",textAlign:"center"}}>(hr)</h5>
    </div></SwiperSlide>
    <SwiperSlide> <div className=" paddin-bott">
        <div className='image-center'>
        <img className='imageradus'  src="https://i.pinimg.com/736x/de/59/4e/de594ec09881da3fa66d98384a3c72ff.jpg" alt=""  />  
        </div>
     
      <h6 style={{textAlign:"center", color:"white", padding:"10px"}}> samir</h6>
      <h5 style={{color:"white",textAlign:"center"}}>(Supervisor)</h5>
    </div></SwiperSlide>
    <SwiperSlide> <div className=" paddin-bott">
        <div className='image-center'>
        <img className='imageradus'  src="https://i.pinimg.com/736x/de/59/4e/de594ec09881da3fa66d98384a3c72ff.jpg" alt=""  />  
        </div>
     
      <h6 style={{textAlign:"center", color:"white", padding:"10px"}}>aman </h6>
      <h5 style={{color:"white",textAlign:"center"}}>(Manager)</h5>
    </div></SwiperSlide>
  
   
     
    </Swiper>
  </>
  )
}

export default Team
