import React, { useRef, useState } from "react";
import Hadder from "../components/Hadder";
import Footer from "../components/footer";
import ban2 from "../assets/ban2.jpg";
import Sociallink from "../components/Sociallink";
import { FaLocationDot, FaFacebookF } from "react-icons/fa6";
import { AiFillMail, AiOutlineInstagram } from "react-icons/ai";
import { BiSolidPhoneCall } from "react-icons/bi";
import { BsTwitter } from "react-icons/bs";
import $ from "jquery";
import { MetaTags } from "react-meta-tags";
const Contectus = (props) => {
  // email
  const [custName, setCustName] = useState("");
  const [custEmail, setCustEmail] = useState("");
  const [custContact, setCustContact] = useState("");
  const [custSubject, setCustSubject] = useState("");
  const [custMessage, setCustMessage] = useState("");
  //////////
  const Appoinment = (e) => {
    var body =
      '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#eb6e14">Sk World Enquiry</h2><p>Hello</p><p>Thank you for your interest in our products/services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left;color:#0a500a"><strong>Name:</strong></td><td style="text-align:left">' + custName + '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>Email Id:</strong></td><td style="text-align:left">' + custEmail + '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>Contact No:</strong></td><td style="text-align:left">' + custContact + '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>subject:</strong></td><td style="text-align:left">' + custSubject + '</td></tr><tr><td style="text-align:left;color:#0a500a"><strong>Message:</strong></td><td style="text-align:left">' + custMessage + '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at sk world</p></div></body></html>';
    // console.log(custServices)
    $.post(
      "https://skdm.in/server/v1/send_lead_mail.php",
      {
        toEmail: "skworldgroup@gmail.com",
        fromEmail: "skdmlead@gmail.com",
        bccMail: "skdmlead@gmail.com",
        mailSubject: "New Customer Lead",
        mailBody: body,
        accountName: "skworld",
        accountLeadSource: "https://skdm.in/",
        accountLeadName: custName,
        // accountLeadEmail: custEmail,
      },
      function (dataa, status) {
        console.log("data :" + dataa);
        // console.log("name:" + custName);
        // console.log("name:" + custEmail);
        // console.log("name:" + custCheckIn);
      }
    );

    alert("Your Form has Submitted Our team will contact with You  soon.");
    e.preventDefault();

    return false;
  };

  return (
    <div>
      <Hadder></Hadder>
      <MetaTags>
        <title>Best Celebrity Management Company in Andheri | SK World</title>
        <meta title="Best Celebrity Management Company in Andheri | SK World" />
        <meta
          name="description"
          content="Discover the Best Celebrity Management Company in Andheri, Mumbai, offering unparalleled talent representation and event services."
        />
        <meta
          name="keywords"
          content="indias-best-celebrity-management-agency-mumbai,event-management-company-andheri,celebrity-management-company-andheri,top-event-management-company-andheri,best-celebrity-management-company-in-andheri"
        />
        <link
          rel="canonical"
          href="https://skworld.in/best-celebrity-management-company-in-andheri"
        />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta
          property="og:title"
          content="Best Celebrity Management Company in Andheri | SK World"
        />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://skworld.in/" />
        <meta
          property="og:description"
          content="Discover the Best Celebrity Management Company in Andheri, Mumbai, offering unparalleled talent representation and event services."
        />
        <meta
          property="og:image"
          content="https://skworld.in/static/media/logo.0a02f996c9ddeb40558c.png"
        />
      </MetaTags>
      <section
        id="breadcrumb-section"
        className="breadcrumb-section clearfix margintop"
      >
        <div
          className="jarallax"
          style={{
            backgroundImage: "none",
            zIndex: 0,
            backgroundImage: "radial-gradient(circle, #808080, #5f5f5f, #3f3f3f, #222222, #000000)",
          }}
        >
          <div className="overlay-black">
            <div className="container">
              <div className="row justify-content-center">
                <div className="col-lg-6 col-md-12 col-sm-12">
                  {/* breadcrumb-title - start */}
                  <div className="breadcrumb-title text-center mb-50">
                    <span className="sub-title">CONTACT US NOW</span>

                    <h1 className="big-title">
                      <strong>
                        Best Celebrity Management Company in Andheri
                      </strong>
                    </h1>
                  </div>
                  {/* breadcrumb-title - end */}
                  {/* breadcrumb-list - start */}
                  <div className="breadcrumb-list">
                    <ul>
                      <li className="breadcrumb-item">
                        <a href="#" className="breadcrumb-link">
                          Home
                        </a>
                      </li>
                      <li
                        className="breadcrumb-item active"
                        aria-current="page"
                      >
                        Contact Us
                      </li>
                    </ul>
                  </div>
                  {/* breadcrumb-list - end */}
                </div>
              </div>
            </div>
          </div>
          <div
            id="jarallax-container-0"
            style={{
              position: "absolute",
              top: 0,
              left: 0,
              width: "100%",
              height: "100%",
              overflow: "hidden",
              pointerEvents: "none",
              zIndex: -100,
            }}
          >
            <div
              style={{
                backgroundPosition: "50% 50%",
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                // backgroundImage: `url(${ban2})`,
                backgroundColor:"black",
                position: "fixed",
                top: 0,
                left: 0,
                width: "1409.6px",
                height: "553.66px",
                overflow: "hidden",
                pointerEvents: "none",
                marginTop: "49.17px",
              }}
            />
          </div>
        </div>
      </section>
      <section
        id="contact-section"
        className="contact-section sec-ptb-100 clearfix"
       
      >
        <div className="container">
          {/* section-title - start */}
          <div className="section-title mb-50">
            <small className="sub-title">contact us</small>
            <h2 className="big-title">
              Keep in touch <strong>with SK WORLD</strong>
            </h2>
          </div>
          {/* section-title - end */}
          {/* contact-form - start */}

          {/* contact-form - end */}
        </div>
      </section>
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="about-wrapper">
              <div className="row">
                <div className="col-md-6">
                  <div className="basic-info ul-li-block marcon">
                    <h3 className="social-title" style={{ color: "white" }}>
                      Contect Details
                    </h3>
                    <ul>
                      <li style={{ display: "flex", color: "white" }}>
                        <div
                          className="fas fa-map-marker-alt"
                          style={{ marginRight: "10px", fontSize: "20px" }}
                        >
                          <FaLocationDot style={{ color: "white" }} />
                        </div>
                        Kuber Complex, 115,1st, New Link Rd, Andheri West,
                        Mumbai, Maharashtra 400053
                      </li>
                      <li style={{ display: "flex", color: "white" }}>
                        <div
                          className="fas fa-envelope"
                          style={{ marginRight: "10px", fontSize: "20px" }}
                        >
                          <AiFillMail style={{ color: "white" }} />
                        </div>
                        <a href="#" style={{color:"white"}}>contact@pantero.com</a>
                      </li>
                      <li style={{ display: "flex", color: "gray" }}>
                        <div
                          className="fas fa-phone"
                          style={{ marginRight: "10px", fontSize: "20px" }}
                        >
                          <BiSolidPhoneCall style={{ color: "white" }} />
                        </div>
                        <a href="#" style={{color:"white"}}>9892331133</a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="social-links ul-li">
                    <h3 className="social-title" style={{ color: "white" }}>
                      {" "}
                      Social network
                    </h3>
                    <ul>
                      <li>
                        <a href="#" className="paddiicon">
                          <div className="fab fa-facebook-f ">
                            <FaFacebookF style={{ color: "white" }} />
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#" className="paddiicon">
                          <i className="fab fa-twitter " />
                          <AiOutlineInstagram style={{ color: "white" }} />
                        </a>
                      </li>
                      <li>
                        <a href="#" className="paddiicon">
                          <div className="fab fa-twitch ">
                            <BsTwitter style={{ color: "white" }} />
                          </div>
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-google-plus-g" />
                        </a>
                      </li>
                      <li>
                        <a href="#">
                          <i className="fab fa-instagram" />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              {/* basic-info - end */}
              {/* social-links - start */}

              {/* social-links - end */}
            </div>
            <div>
              <iframe
                src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d482475.1397475651!2d72.832383!3d19.136719!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b6236ab7a919%3A0xd09e3f0db62b7cf8!2sSK%20WORLD%20PRIVATE%20LIMITED!5e0!3m2!1sen!2sin!4v1694780960285!5m2!1sen!2sin"
                className="mapimg"
                style={{ border: 0 }}
                allowFullScreen=""
                loading="lazy"
                referrerPolicy="no-referrer-when-downgrade"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="contact-form form-wrapper text-center">
              <form action="contact.php" method="post" {...props}>
                <div className="row">
                  <div className="padditop10">
                    <div className="form-item">
                      <input
                        type="text"
                        placeholder="Your Name"
                        required=""
                        onChange={(e) => setCustName(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="padditop10">
                    <div className="form-item">
                      <input
                        type="email"
                        placeholder="Email Address"
                        required=""
                        onChange={(e) => setCustEmail(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="padditop10">
                    <div className="form-item">
                      <input
                        type="tel"
                        placeholder="Contect Us"
                        required=""
                        onChange={(e) => setCustContact(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="padditop10">
                    <div className="form-item">
                      <input
                        type="text"
                        placeholder="Subject"
                        required=""
                        onChange={(e) => setCustSubject(e.target.value)}
                      />
                    </div>
                  </div>
                  <div className="padditop10">
                    <div className="padditop10">
                      <textarea
                        placeholder="Your Message"
                        required=""
                        defaultValue={""}
                        onChange={(e) => setCustMessage(e.target.value)}
                      />
                    </div>
                    <button
                      type="submit"
                      className="custom-btn"
                      onClick={Appoinment}
                    >
                      send mail
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Sociallink></Sociallink>
      <Footer></Footer>
    </div>
  );
};

export default Contectus;
