import React, { useState } from 'react';
import Hadder from '../components/Hadder'
import Slider from '../components/Slider'
import Footer from '../components/footer'
import Swiper from 'swiper'
import Film from '../components/film'
import Celebration from '../components/celebration'
import {MdHouseSiding,MdGroups} from "react-icons/md";
import { BsBalloonFill } from 'react-icons/bs';
import { BiPhoneCall } from 'react-icons/bi';
import {AiTwotoneBulb} from "react-icons/ai";
import { FaHandshake,FaGlassCheers } from 'react-icons/fa';
import{SlCalender} from "react-icons/sl";
import Admaking from '../components/Admaking'
import ban2 from "../assets/ban2.jpg"
import ADDmaking from '../components/ADDmaking'
import Testimonial from '../components/testimonial'
import Sociallink from "../components/Sociallink"
import { Link } from 'react-router-dom';
import { MetaTags } from 'react-meta-tags';
import Eventglimps from '../components/Eventglimps';
import Team from '../components/Team';

const Home = () => {
  const [activeSection, setActiveSection] = useState(null);

  const handleNavClick = (sectionId) => {
    setActiveSection(sectionId);
    const targetSection = document.querySelector(sectionId);
    if (targetSection) {
      targetSection.scrollIntoView({ behavior: 'smooth' });
    }
  };
  return (
    <div>
      <Hadder></Hadder>
   
    <div className='sliderclass'><Slider/></div>
    <MetaTags>
        <title> Event Management Company in Andheri | SK World</title>
        <meta title=" Event Management Company in Andheri | SK World" />
        <meta name="description" content=" Looking for an Event Management Company in Andheri? Our seasoned professionals deliver top-notch event planning & execution services. Contact us today!" />
        <meta name="keywords" content="indias-best-celebrity-management-agency-mumbai,event-management-company-andheri,celebrity-management-company-andheri,top-event-management-company-andheri,best-celebrity-management-company-in-andheri" />
        <link rel="canonical" href="https://skworld.in/" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content=" Event Management Company in Andheri | SK World" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://skworld.in/" />
        <meta property="og:description" content=" Looking for an Event Management Company in Andheri? Our seasoned professionals deliver top-notch event planning & execution services. Contact us today!" />
        <meta property="og:image" content="https://skworld.in/static/media/logo.0a02f996c9ddeb40558c.png" />
      </MetaTags>
      <h1 style={{display:"none"}}> India's Best Celebrity Management Agency in Mumbai</h1>
      <div style={{borderTop:"1px solid white"}}>

      </div>
      <section id="award-section" style={{backgroundColor:"black"}} className="award-section sec-ptb-100 clearfix" >
  <div className="container">
    <div className="row" >
      {/* company-age - start */}
      <div className="col-lg-6 col-md-12 col-sm-12">
        <div
          className="company-age sr-fade1"
          style={{ backgroundImage: `url(${ban2})` }}
        >
          
        </div>
      </div>
      {/* company-age - end */}
      <div className="col-lg-6 col-md-12 col-sm-12">
        {/* section-title - start */}
        <div className="section-title text-left margtop10 sr-fade1">
          <small className="sub-title" style={{color:"white"}} >SK World</small>
          <h2 className="big-title" style={{color:"white"}}>
            About  <strong>SK World</strong>
          </h2>
        </div>
        {/* section-title - end */}
        {/* awaed-item-area - start */}
        <p style={{ textAlign:"justify",color:"white"}}>
  SK WORLD ENTERTAINMENT is a fully-fledged Entertainment company with verticals in LIVE EVENTS, CORPORATE EVENTS, CELEBRITY MANAGEMENT, and ADVERTISING.

SK WORLD ENTERTAINMENT is one of the TOP celebrity management companies in INDIA
  </p>
  <p style={{ textAlign:"justify",color:"white"}}>
  Sunil Kumar (CMD) is a Reputed name in Regional Film Industry, he comes with around 12 years of acting experience as a lead in Odia, Bengali & Marathi films.
  </p>
  <p style={{ textAlign:"justify",color:"white"}}>
  Sunil Kumar started this venture with a vision to mobilize his experience in the field of Events, Advertising, Talent Management etc and share it with others. It is his Association with the film industry of bridging the gap between the client requirement of any Artist / Celebrity & providing them the same at the best commercials propelled him towards steering on his own.
  </p>
        {/* awaed-item-area - end */}
      </div>
    </div>
  </div>
</section>
<div style={{borderTop:"1px solid white"}}>

<section
  id="upcomming-event-section"
  className="upcomming-event-section sec-ptb-100 clearfix"
  style={{backgroundColor:"black"}}
>
  <div className="container" id='section1'>
    {/* section-title - start */}
    <div className="section-title text-center mb-50">
      <small className="sub-title">Team</small>
      <h2 className="big-title">
        Our <strong> Team</strong>
      </h2>
    </div>
   
  
  <Team/>

  </div>

</section>
<div style={{borderTop:"1px solid white"}}>

      </div>
</div>
     {/* <div className='textcent'>
      <div className="link-groups">
          <Link className="about-btn custom-btn" style={{margin:"0px 35px 0px 0px "}} to="/event-management-company-andheri"> About us</Link>
         
          
         <Link  className="second-buttion" style={{margin:"0px 0px 0px 35px"}} to="/celebrity-management-company-andheri">Services</Link>
          </div>
          </div> */}
      
      {/* <div className="row " style={{margin:"15px" }}>
        
        <div className=" col-sm-12 col-md-4  mt-2 centerone">
      
            <button  onClick={() => handleNavClick('#section2')} style={{width:"215px"}} className="about-btn custom-btn">
              Celebrity Management
            </button>
          
        </div>
        <div className=" col-sm-12 col-md-4  mt-2 centerone">
      
      <button onClick={() => handleNavClick('#section4')} style={{width:"215px"}} className="about-btn custom-btn">
        Ad Film Making
      </button>
    
  </div> */}

        {/* <div className="col-sm-12 mt-2 centerone">
      
            <button onClick={() => handleNavClick('#section3')}  className="about-btn custom-btn">
              Films Making
            </button>
          
        </div> */}
        
        {/* <div className="col-sm-12 col-md-4  mt-2 centerone">
      
            <button onClick={() => handleNavClick('#section1')} style={{width:"215px"}} className="about-btn custom-btn">
              Event Management
            </button>
          
        </div>
       
      </div> */}
      {/* <div className="owl-item" style={{ width: "100%" }}>
  <div
    className="item"
    style={{ backgroundImage: "url(https://jthemes.net/themes/html/harmony-event/assets/images/slider/slider-bg3.jpg)" }}
  >
    <div className="overlay-black">
      <div className="container">
        <div className="slider-item-content">
          <span className="medium-text">one stop</span>

          <small className="small-text">every event sould be perfect</small>
          <div className="link-groups">
            <a  className="about-btn custom-btn">
              about us
            </a>
            <a href="#!" className="start-btn">
              get started!
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div> */}
<section
  id="upcomming-event-section"
  className="upcomming-event-section sec-ptb-100 clearfix"
  style={{backgroundColor:"black"}}
>
  <div className="container" id='section1'>
    {/* section-title - start */}
    <div className="section-title text-center mb-50">
      <small className="sub-title">Events</small>
      <h2 className="big-title">
        Latest <strong> Events</strong>
      </h2>
    </div>
   
  
  <Eventglimps/>

  </div>

</section>
<div style={{borderTop:"1px solid white"}}>

</div>
<section
  id="upcomming-event-section"
  className="upcomming-event-section sec-ptb-100 clearfix"
  style={{backgroundColor:"black"}}
>
  <div className="container" id='section2'>
    {/* section-title - start */}
    <div className="section-title text-center mb-50">
      <small className="sub-title">Celebrity</small>
      <h2 className="big-title" style={{color:"white"}}>
        our <strong>Celebrity </strong>
      </h2>
    </div>

    <Film></Film>
   
    <div className="row">
     
  
    
      
    </div>
  </div> 

</section>


{/*     
<section id="about-section" className="about-section sec-ptb-100 clearfix">
  <div className="container">
    <div className="row"> */}
      {/* section-title - start */}
      {/* <div className="col-lg-4 col-md-12 col-sm-12">
        <div className="section-title text-left mb-30">
          <span className="line-style" />
          <small className="sub-title">we are Sk World</small>
          <h2 className="big-title" style={{color:"#333333"}}>
            <strong>No.1</strong> Celebrity Management
          </h2>
          <p className="black-color mb-50" style={{textAlign:"justify"}}>
          India's Best Celebrity Management Agency in Mumbai plays a pivotal role in shaping and advancing the careers of renowned personalities in the Indian entertainment scene. 

SK World is regarded as one of the most prominent and highly regarded celebrity management agencies in Mumbai with its headquarters located in the heart of Bollywood, SK World has established itself as a powerhouse in the industry.

          </p>
          <Link to="/event-management-company-andheri" className="custom-btn">
            about SK worlds
          </Link>
        </div>
      </div> */}
      {/* section-title - end */}
      {/* about-item-wrapper - start */}
      {/* <div className="col-lg-8 col-md-12 col-sm-12">
        <div className="about-item-wrapper ul-li">
          <ul>
            <li>
              <a href="#" className="about-item">
                <span className="icon">
                <div className="flaticon-handshake fontsize30 onee" >
             <FaHandshake/>
              </div>
              </span>
                <strong className="title">Friendly Team</strong>
                <small className="sub-title">More than 200 teams</small>
              </a>
            </li>
            <li>
              <a href="#" className="about-item">
                <span className="icon">
                <div className="flaticon-two-balloons fontsize30 onee" >
              <BsBalloonFill/>
              </div>
                </span>
                <strong className="title">perfact venues</strong>
                <small className="sub-title">perfact venues</small>
              </a>
            </li>
            <li>
              <a href="#" className="about-item">
                <span className="icon">
                <div className="flaticon-cheers fontsize30 onee" >
              <FaGlassCheers/>
              </div>
                </span>
                <strong className="title">Unique Scenario</strong>
                <small className="sub-title">We thinking out of the box</small>
              </a>
            </li>
            <li>
              <a href="#" className="about-item">
                <span className="icon">
                <div className="flaticon-clown-hat fontsize30 onee">
              <MdHouseSiding/>
              </div>
                </span>
                <strong className="title">Unforgettable Time</strong>
                <small className="sub-title">We make you perfect event</small>
              </a>
            </li>
            <li>
              <a href="#" className="about-item">
                <span className="icon">
                <div className="flaticon-speech-bubble fontsize30 onee" >
                <BiPhoneCall/>
                </div>
                </span>
                <strong className="title">24/7 Hours Support</strong>
                <small className="sub-title">Anytime anywhere</small>
              </a>
            </li>
            <li>
              <a href="#" className="about-item">
                <span className="icon">
                <div className="flaticon-light-bulb fontsize30 onee" >
              <AiTwotoneBulb/>
                </div>
                </span>
                <strong className="title">Briliant Idea</strong>
                <small className="sub-title">We have million idea</small>
              </a>
            </li>
          </ul>
        </div>
      </div> */}
      {/* about-item-wrapper - end */}
    {/* </div>
  </div>
</section> */}
<div style={{borderTop:"1px solid white"}}>

</div>
<section
  id="upcomming-event-section"
  className="upcomming-event-section sec-ptb-100 clearfix"
  style={{backgroundColor:"black"}}
>
  <div className="container" id='section3'>
    {/* section-title - start */}
    <div className="section-title text-center mb-50">
      <small className="sub-title">Films</small>
      <h2 className="big-title">
        Latest <strong> Films</strong>
      </h2>
    </div>

 <Admaking></Admaking>
  

  </div>

</section>


{/* <section
  id="special-offer-section"
  className="special-offer-section clearfix"
  style={{ backgroundColor:"white" }}
>
  <div className="container">
    <div className="row"> */}
      {/* special-offer-content - start */}
      {/* <div className="col-lg-9 col-md-12 col-sm-12">
        <div className="special-offer-content">
          <h2 style={{color:"black"}}>

            <span>"Inquiry Unleashed Crafting Celebrity Destinies Together"</span>
          </h2>
          <p className="m-0">
            Contact us now and we will make your event unique &amp;
            unforgettable
          </p>
        </div>
      </div> */}
      {/* special-offer-content - end */}
      {/* event-makeing-btn - start */}
      {/* <div className="col-lg-3 col-md-12 col-sm-12">
        <div className="event-makeing-btn">
          <Link to="/celebrity-management-company-andheri" style={{color:"white",backgroundColor:"black"}}>
          make an event now
          </Link>
        </div>
      </div> */}
      {/* event-makeing-btn - end */}
    {/* </div>
  </div>
</section> */}
<div style={{borderTop:"1px solid white"}}>

</div>
<section
  id="upcomming-event-section"
  className="upcomming-event-section sec-ptb-100 clearfix"
>
  <div className="container" id='section4'>
    {/* section-title - start */}
    <div className="section-title text-center mb-50">
      <small className="sub-title">AD Making</small>
      <h2 className="big-title">
        Latest <strong> AD Making</strong>
      </h2>
    </div>
   
 <ADDmaking></ADDmaking>


  </div>

</section>

{/* <section
  id="event-gallery-section"
  className="event-gallery-section sec-ptb-100 clearfix"
> */}
  {/* section-title - start */}
  {/* <div className="section-title text-center mb-50">
    <small className="sub-title">sk world gallery</small>
    <h2 className="big-title">
      Beautiful &amp; <strong>Unforgettable Times</strong>
    </h2>
  </div> */}
  {/* section-title - end */}
 
  {/* <div
    className="grid zoom-gallery clearfix mb-80"
    data-isotope='{ "masonry": { "columnWidth": 0 } }'
    style={{ position: "relative"}}
  >
   
    <div
      className="grid-item photo-gallery "
      data-category="photo-gallery"
     
    >
      <a className="popup-link" >
        <img src="https://img.freepik.com/free-photo/happy-people-celebrating-having-fun_23-2147651892.jpg?t=st=1692193515~exp=1692194115~hmac=9e7c84834f2f229c02404badfd072a6ea68a99b4f759de0d3df674d245745c60" alt="best celebrity management in andheri"  />
      </a>
      <div className="item-content">
        <h3>Envato Author Fun Hiking</h3>
        <span>Food Festival, 24 June 2016</span>
      </div>
    </div>
    <div
      className="grid-item photo-gallery "
      data-category="photo-gallery"
     
    >
      <a className="popup-link" >
        <img src="https://cdn0.weddingwire.in/vendor/7324/original/960/jpg/akshit-stephanie-haldi-102_15_77324-168353443650817.webp" alt="event management " />
      </a>
      <div className="item-content">
        <h3>John Doe Wedding day</h3>
        <span>Wedding Party, 24 June 2016</span>
      </div>
    </div>
  
    <div
      className="grid-item video-gallery "
      data-category="video-gallery"
      
    >
      <a className="popup-youtube" >
        <img src="https://img.freepik.com/free-photo/happy-people-celebrating-having-fun_23-2147651892.jpg?t=st=1692193515~exp=1692194115~hmac=9e7c84834f2f229c02404badfd072a6ea68a99b4f759de0d3df674d245745c60" alt="best event management in andheri" />
      </a>
      <div className="item-content">
        <h3>New Year Celebration</h3>
        <span>Food Festival, 24 June 2016</span>
      </div>
    </div>
    <div
      className="grid-item photo-gallery "
      data-category="photo-gallery"
      
    >
      <a className="popup-link" >
        <img src="https://cdn0.weddingwire.in/vendor/7324/original/960/jpg/akshit-stephanie-haldi-102_15_77324-168353443650817.webp" alt=" celebrity management " />
      </a>
      <div className="item-content">
        <h3>Envato Author Fun Hiking</h3>
        <span>Food Festival, 24 June 2016</span>
      </div>
    </div>
  </div>
  <div className="text-center">
    <a href="#" style={{color:"white"}} className="custom-btn">
      <Link to="/top-event-management-company-andheri" style={{color:"white"}}> 
      view all gallery
      </Link>
    </a>
  </div>
</section>
<section
  id="testimonial5-section"
  className="testimonial5-section sec-ptb-100 clearfix"
  style={{backgroundColor:"white"}}
>
  <div className="container"> */}
    {/* section-title - start */}
    <div className="section-title text-center  sr-fade1" style={{backgroundColor:"white"}}>
      <small className="sub-title">Video</small>
      <h2 className="big-title" style={{color :"black"}}>
        Our <strong>Video</strong>
      </h2>
    </div>
    {/* section-title - end */}
    {/* testimonial5-carousel - start */}
    <div
      id="testimonial5-carousel"
      className="testimonial5-carousel sr-fade2 owl-carousel owl-theme owl-loaded owl-drag"
    style={{backgroundColor:"white"}}>
      <div style={{textAlign:"center",justifyContent:"center"}}>
      <iframe width="100%" height="100%" src="https://www.youtube.com/embed/UsRorLzTsig?si=-oGPAIf90Hn0DkrZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowfullscreen></iframe>
</div>

      
    </div>
    {/* testimonial5-carousel - end */}
  {/* </div>
</section> */}
<section
  id="testimonial5-section"
  className="testimonial5-section sec-ptb-100 clearfix"
  style={{backgroundColor:"black"}}
>
  <div className="container">
    {/* section-title - start */}
    <div className="section-title text-center mb-80 sr-fade1">
      <small className="sub-title">testimonials</small>
      <h2 className="big-title">
        clients <strong>testimonial</strong>
      </h2>
    </div>
    {/* section-title - end */}
    {/* testimonial5-carousel - start */}
    <div
      id="testimonial5-carousel"
      className="testimonial5-carousel sr-fade2 owl-carousel owl-theme owl-loaded owl-drag"
    >
   <Testimonial></Testimonial>
      
    </div>
    {/* testimonial5-carousel - end */}
  </div>
</section>

<div style={{display:"none"}}>
Celebrity Management Company has solidified its reputation as India's Premier Celebrity Management Agency through a combination of distinctive qualities 
as the agency offers strategic career guidance, going beyond securing opportunities to provide clients with expert advice on project selection and career trajectory and their expertise extends to effective public relations, ensuring their clients maintain a positive image in the media and the public eye.

SK World boasts remarkable event management skills, with a proven track record in organising high-profile events such as film premieres and award ceremonies and their meticulous attention to detail guarantees the success of every occasion.

</div>
<Sociallink></Sociallink>
<Footer></Footer>

    </div>
  )
}

export default Home
